import React, { useEffect, useState } from 'react';
import { func, string, objectOf, arrayOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import HomeGrid from '../../HomeGrid';
import HomeInput from '../../HomeInput';
import HomeModal from '../../HomeModal';
import Title from '../Title';
import { deal } from '../../../utils/schemas';
import FORM from '../../../constants/form';
import CTA from '../../../constants/cta';
import MESSAGE from '../../../constants/messages';
import { FILTER_KEY } from '../../../constants/filters';
import { sanitizedDeal } from '../../../utils/sanitizeFormValues';
import { formData } from '../../../utils/file';
import { createDealProps } from '../props';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(0, 0, 2),
    marginBottom: spacing(5),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cta: {
    border: 'none',
    outline: 'none',
    width: '100%',
    maxWidth: spacing(40),
    marginTop: spacing(2),
    padding: spacing(1.5),
    borderRadius: shape.rectRadius,
    background: palette.secondary.light,
    textTransform: 'uppercase',
  },
  ctaActive: {
    background: palette.primary.dark,
    color: palette.primary.contrastText,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const Share = ({
  title,
  token,
  user,
  detail,
  onCancel,
  dataBag,
  onSubmit,
  createdId,
  fileUpload,
  onUpload,
  onResetDeal,
  onResetFile,
}) => {
  const s = useStyles();
  const { CREATE_DEAL, FIELD_CHUNK, FIELD_NAMES } = FORM;
  const { BRAND, BRANCH, TYPE, METHOD } = FILTER_KEY;
  const { SLUG, PICTURE, QR, DEAL } = FIELD_NAMES;
  const formChunks = chunk(CREATE_DEAL, FIELD_CHUNK);
  const [branchList, setBranchList] = useState([]);
  const [sanitizedBag, setSanitizedBag] = useState({});
  const [picture, setPicture] = useState(null);
  const [qr, setQr] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [initDetail, setInitDetail] = useState({});
  const [dealId, setDealId] = useState('');
  const [mode, setMode] = useState(0);

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
    if (toggleModal) {
      onCancel();
    }
  };

  const convertModelKey = name =>
    name
      .replace(BRANCH, '$&es')
      .replace(TYPE, '$&s')
      .replace(METHOD, '$&s');

  useEffect(() => {
    if (!isEmpty(detail)) {
      setMode(1);
      setDealId(get(detail, 'id', ''));
      const initialValues = CREATE_DEAL.reduce((acc, key) => {
        const keyMap = convertModelKey(key.name);
        const keyName = key.name;
        let value = detail[keyMap];
        const slugIndex = keyName.search(SLUG);
        if (Number(slugIndex) > -1) {
          value = get(value, 'name');
        }
        return {
          ...acc,
          [keyName]: value,
        };
      }, {});
      const branches = get(detail, `branches`);
      setBranchList(branches);
      setPicture(get(detail, PICTURE, null));
      setQr(get(detail, QR, null));
      setInitDetail(initialValues);
    } else {
      setMode(0);
    }
  }, [detail]);

  useEffect(() => {
    setSanitizedBag({
      ...dataBag,
      [BRANCH]: branchList,
    });
  }, [branchList]);

  useEffect(() => {
    if (createdId) {
      const currentPix = Boolean(get(picture, 'id', ''));
      const currentQr = Boolean(get(qr, 'id', ''));
      const freezePix = currentPix || !(currentPix || picture);
      const freezeQr = currentQr || !(currentQr || qr);
      if (picture && !currentPix) {
        const uploadPix = formData(picture, createdId, DEAL, PICTURE);
        onUpload(uploadPix, token);
      }
      if (qr && !currentQr) {
        const uploadQR = formData(qr, createdId, DEAL, QR);
        onUpload(uploadQR, token);
      }
      if (freezePix && freezeQr) {
        handleToggleModal();
      }
      onResetDeal();
    }
  }, [createdId]);

  useEffect(() => {
    const currentPix = Boolean(get(picture, 'id', null));
    const currentQr = Boolean(get(qr, 'id', null));
    if (!(currentPix && currentQr) && !isEmpty(fileUpload)) {
      handleToggleModal();
      onResetFile();
    }
  }, [fileUpload]);

  const handleSubmitDeal = values => {
    setPicture(get(values, PICTURE, null));
    setQr(get(values, QR, null));
    onSubmit(
      sanitizedDeal({
        ...values,
        id: dealId,
        user,
      }),
      token,
    );
  };

  return (
    <HomeGrid fluid direction="column" className={s.root}>
      <HomeModal
        isOpen={toggleModal}
        onClose={handleToggleModal}
        message={MESSAGE.UPDATE.CONTENT}
      />
      <Title title={title} onClose={onCancel} />
      <HomeGrid fluid direction="column" justify="center" alignItems="center">
        <Formik
          validateOnBlur
          validateOnChange
          enableReinitialize
          initialValues={initDetail}
          onSubmit={handleSubmitDeal}
          validationSchema={deal}
        >
          {({
            handleSubmit,
            handleChange,
            setTouched,
            setFieldValue,
            touched,
            isValid,
            errors,
            dirty,
            values,
          }) => {
            const handleTouched = evt => {
              let name;
              if (evt.preventDefault) {
                evt.preventDefault();
                name = get(evt, 'target.name');
              } else {
                name = evt;
              }
              setTouched({ ...touched, [name]: true });
            };

            const handleSelectItem = name => item => {
              const data = get(values, name, null);
              handleTouched(name);
              if (name === BRAND) {
                setFieldValue(BRANCH, []);
                setBranchList(get(item, 'branches', []));
              }
              if (Array.isArray(data) && Boolean(mode)) {
                setFieldValue(name, [...data, ...item]);
              } else {
                setFieldValue(name, item);
              }
            };

            return (
              <form onSubmit={handleSubmit} className={s.form}>
                <HomeGrid fluid justify="center" spacing={2}>
                  {formChunks.map(fields => (
                    <HomeGrid key={get(fields, '0.label')} className={s.section}>
                      {fields.map(field => (
                        <HomeInput
                          key={field.name}
                          id={field.name}
                          type={field.type}
                          label={field.label}
                          name={field.name}
                          maxLength={field.length}
                          onChange={handleChange}
                          errors={errors}
                          onBlur={handleTouched}
                          touched={touched}
                          list={sanitizedBag[field.tag]}
                          onSelect={handleSelectItem(field.tag)}
                          values={values}
                          multiSelect={field.multiSelect}
                        />
                      ))}
                    </HomeGrid>
                  ))}
                </HomeGrid>
                <HomeGrid fluid justify="center">
                  <button
                    type="submit"
                    className={classNames(s.cta, dirty && isValid && s.ctaActive)}
                  >
                    {CTA.LABELS.DONE}
                  </button>
                </HomeGrid>
              </form>
            );
          }}
        </Formik>
      </HomeGrid>
    </HomeGrid>
  );
};

Share.propTypes = {
  token: string.isRequired,
  user: string.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  onUpload: func.isRequired,
  onResetDeal: func.isRequired,
  onResetFile: func.isRequired,
  dataBag: objectOf(any),
  createdId: string,
  fileUpload: arrayOf(any),
  detail: objectOf(any),
  title: string,
};

Share.defaultProps = {
  detail: {},
  dataBag: {},
  createdId: '',
  fileUpload: [],
  title: '',
};

export default connect(createDealProps.mapStateToProps)(Share);
