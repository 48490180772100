import React from 'react';
import { objectOf, any } from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import HomeCredit from '../../svgs/homecredit-brand.svg';
import TIME from '../../constants/time';
import ROUTER from '../../constants/router';
import COLORS from '../../constants/colors';
import LAYOUT from '../../constants/layout';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.tooltip,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark,
    boxShadow: COLORS.SHADOW_STANDARD,
    height: theme.spacing(LAYOUT.ADMIN_BANNER_HEIGHT),
  },
  logo: {
    cursor: 'pointer',
    padding: theme.spacing(1, 2),
    display: 'flex',
  },
  logoHome: {
    width: theme.spacing(25),
  },
  greeting: {
    fontSize: theme.typography.body2.fontSize,
    color: COLORS.WHITE,
  },
}));

const NavBar = ({ userDetail }) => {
  const s = useStyles();
  const hour = moment().hour();
  let greeting = TIME.GREETING.MORNING;
  if (hour >= TIME.SPLIT.NOON && hour <= TIME.SPLIT.NIGHT) {
    greeting = TIME.GREETING.AFTERNOON;
  }
  if (hour > TIME.SPLIT.NIGHT) greeting = TIME.GREETING.EVENING;

  return (
    <Grid component="header" container justify="space-between" className={s.root}>
      <Grid component="div" item className={s.logo}>
        <Fade in timeout={TIME.EFFECT_TIMEOUT}>
          <Link to={ROUTER.CUSTOMER.ROOT}>
            <HomeCredit className={s.logoHome} />
          </Link>
        </Fade>
      </Grid>
      <Grow in timeout={TIME.EFFECT_TIMEOUT}>
        <Grid component="div" item>
          <Typography gutterBottom className={s.greeting}>
            {greeting}!{' '}
            <strong>
              <i>{userDetail.userName}</i>
            </strong>
          </Typography>
        </Grid>
      </Grow>
    </Grid>
  );
};

NavBar.propTypes = {
  userDetail: objectOf(any).isRequired,
};

export default NavBar;
