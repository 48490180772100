import React, { useEffect, useState, useRef } from 'react';
import { any, objectOf, func, string } from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { uuid } from 'uuidv4';
import EmptyItem from '../emptyItem';
import DealCard from '../deal/dealcard';
import Title from './Title';
import Pagination from '../Pagination';
import HomeGrid from '../HomeGrid';
import COLORS from '../../constants/colors';
import MESSAGE from '../../constants/messages';
import LAYOUT from '../../constants/layout';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: spacing(0, 0, 2),
  },
  cardRow: {
    margin: `${spacing(1)}px auto`,
  },
  card: {
    width: spacing(42),
    height: spacing(48),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  banner: {
    position: 'absolute',
    top: spacing(22),
    padding: spacing(0, 1),
  },
  content: {
    margin: spacing(2, 0, 1),
  },
  cardContent: {
    padding: 0,
  },
  brandName: {
    marginLeft: spacing(1.5),
    color: palette.primary.contrastText,
    width: '80%',
  },
  avatar: {
    border: `0.5px solid ${COLORS.LIGHT_GREY}`,
    width: spacing(6),
    height: spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: palette.grey[50],
  },
  textIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  itemFull: {
    width: '100%',
  },
  itemSemi: {
    width: '90%',
  },
  info: {
    padding: spacing(1.5, 2, 0),
  },
  iconStyle: {
    fill: palette.grey[900],
    width: spacing(2.5),
  },
  media: {
    position: 'relative',
    height: spacing(26.5),
    marginBottom: spacing(1),
    background: palette.primary.light,
  },
  defaultLogo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cta: {
    margin: 'auto',
    width: '100%',
  },
}));

const DealList = ({
  onSearch,
  onSetSearchText,
  searchText,
  onFetchDeals,
  deals,
  onView,
  user,
  token,
}) => {
  const s = useStyles();
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState(1);
  const topPage = useRef();

  const handleSelectPage = item => {
    const data = {
      user,
      page: item,
    };

    if (!searchText) {
      onFetchDeals(data, token);
    } else {
      data.brand = searchText;
      onSearch(data, token);
    }
    setPage(item);
  };

  useEffect(() => {
    handleSelectPage(page);
  }, [user, token]);

  useEffect(() => {
    setList(get(deals, 'deals', []));
    const count = get(deals, 'count', 0);
    setTotal(count);
    setPaging(Math.ceil(count / LAYOUT.ADMIN_PAGE_ITEM));
  }, [deals]);

  const handleSearch = text => {
    const data = { brand: text, user };
    onSetSearchText(text);
    onSearch(data, token);
  };

  const handleClearSearch = () => {
    onSetSearchText('');
    onFetchDeals({ user }, token);
  };

  const title = `${MESSAGE.DEAL.LIST} ${total ? ` - ${total} ưu đãi!` : ''}`;

  return (
    <HomeGrid ref={topPage} fluid direction="column" className={s.root}>
      <Title title={title} onSearch={handleSearch} onClear={handleClearSearch} />
      {list.length > 0 ? (
        <HomeGrid fluid justify="space-evenly">
          {list.map(item => (
            <DealCard key={uuid()} onAdView={onView} item={item} />
          ))}
          <Pagination
            total={paging}
            onSelect={handleSelectPage}
            range={LAYOUT.SHOW_ITEM_SEARCH}
            topRef={topPage}
          />
        </HomeGrid>
      ) : (
        <EmptyItem />
      )}
    </HomeGrid>
  );
};

DealList.propTypes = {
  onView: func.isRequired,
  onFetchDeals: func.isRequired,
  onSearch: func.isRequired,
  onSetSearchText: func.isRequired,
  deals: objectOf(any),
  searchText: string,
  user: string.isRequired,
  token: string.isRequired,
};

DealList.defaultProps = {
  deals: {},
  searchText: '',
};

export default DealList;
