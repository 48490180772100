import React, { useState, useEffect } from 'react';
import { func, objectOf, arrayOf, any, string, object, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import HomeModal from '../../HomeModal';
import HomeInput from '../../HomeInput';
import Title from '../Title';
import HomeGrid from '../../HomeGrid';
import HomeSubmit from '../../HomeSubmit';
import { brandProps } from '../props';
import { branch as branchValues } from '../../../utils/sanitizeFormValues';
import { formData } from '../../../utils/file';
import FORM from '../../../constants/form';
import CTA from '../../../constants/cta';
import MESSAGE from '../../../constants/messages';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    background: palette.background.paper,
    paddingBottom: spacing(5),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Share = ({
  user,
  token,
  createdBranch,
  deletedBranch,
  onLoadItems,
  onCreateItem,
  onUpdateItem,
  initSchemas,
  onResetItem,
  onReset,
  onCancel,
  onUpload,
  onResetFile,
  isError,
  initItem,
  fields,
  schemas,
  dataBag,
}) => {
  const s = useStyles();
  const [logo, setLogo] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [mode, setMode] = useState(0);
  const [initValues, setInitValues] = useState({});
  const [hasLogo, setHasLogo] = useState(false);
  const [promptMessage, setMessage] = useState(MESSAGE.CREATE_BRANCH.MESSAGE);
  const [ctaLabel, setCtaLabel] = useState(CTA.LABELS.CREATE);
  const [wardList, setWardList] = useState([]);
  const [sanitizedBag, setSanitizedBag] = useState({});
  const [isReset, setIsReset] = useState(false);
  const { FIELD_NAMES } = FORM;
  const { LOCATION, WARD } = FIELD_NAMES;
  const formChunks = chunk(fields, 3);

  useEffect(() => {
    if (!isEmpty(initItem)) {
      setMode(1);
      setInitValues({
        id: get(initItem, 'id'),
        ...initSchemas.reduce(
          (acc, key) => ({
            ...acc,
            [key[0]]: initItem[key[1]],
          }),
          {},
        ),
      });
    } else {
      setMode(0);
    }
  }, [initItem]);

  useEffect(() => {
    if (isError) {
      setLogo(null);
    }
  }, [isError]);

  useEffect(() => {
    setSanitizedBag({
      ...dataBag,
      [WARD]: wardList,
    });
  }, [wardList, dataBag]);

  useEffect(() => {
    if (createdBranch) {
      if (logo && !hasLogo) {
        const data = formData(
          logo,
          createdBranch,
          FIELD_NAMES.BRANCH,
          FIELD_NAMES.PICTURE,
        );
        onUpload(data, token);
      }
      setIsSuccess(true);
    }
  }, [createdBranch]);

  useEffect(() => {
    if (deletedBranch) {
      setIsDeleted(true);
    }
  }, [deletedBranch]);

  useEffect(() => {
    if (mode) {
      setMessage(MESSAGE.CREATE_BRANCH.UPDATE);
      setCtaLabel(CTA.LABELS.UPDATE);
    } else {
      setMessage(MESSAGE.CREATE_BRANCH.MESSAGE);
      setCtaLabel(CTA.LABELS.CREATE);
    }
  }, [mode]);

  const handleSubmitBrand = (values, { resetForm }) => {
    setHasLogo(get(values, `${FIELD_NAMES.PICTURE}.id`, ''));
    setLogo(get(values, FIELD_NAMES.PICTURE));
    const data = { ...branchValues(values), user };
    if (mode) {
      data.id = get(initValues, 'id');
      onUpdateItem(data, token);
    } else {
      onCreateItem(data, token);
    }
    setInitValues({});
    resetForm();
    setIsReset(true);
  };

  const handleCleanUp = () => {
    setInitValues({});
    setWardList([]);
    onResetItem();
    onResetFile();
    onReset({});
    onLoadItems(1);
    setIsReset(false);
  };

  const handleClosePromptModal = () => {
    setIsSuccess(false);
    setLogo(null);
    handleCleanUp();
  };

  const handleCloseDeleted = () => {
    setIsDeleted(false);
    handleCleanUp();
  };

  const handleCancel = () => {
    handleCleanUp();
    onCancel();
  };

  const handleSwitchMode = () => {
    if (mode) {
      setInitValues({});
      onReset({});
    } else {
      handleCancel();
    }
  };

  return (
    <>
      <HomeModal
        isOpen={isSuccess}
        title={MESSAGE.CREATE_BRANCH.TITLE}
        message={promptMessage}
        onClose={handleCancel}
        onConfirm={handleClosePromptModal}
      />
      <HomeModal
        isOpen={isDeleted}
        title={MESSAGE.CREATE_BRANCH.TITLE}
        message={MESSAGE.CREATE_BRANCH.DELETED_MSG}
        onClose={handleCloseDeleted}
      />
      <HomeGrid fluid direction="column" className={s.root}>
        <Title title={MESSAGE.CREATE_BRANCH.TITLE} onClose={handleSwitchMode} />
        <HomeGrid fluid justify="center">
          <Formik
            initialValues={initValues}
            enableReinitialize
            validateOnBlur
            validateOnMount
            validateOnChange
            validationSchema={schemas}
            onSubmit={handleSubmitBrand}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              setTouched,
              isValid,
              touched,
              errors,
              values,
              dirty,
            }) => {
              const handleTouched = evt => {
                let name;
                if (evt.preventDefault) {
                  evt.preventDefault();
                  name = get(evt, 'target.name');
                } else {
                  name = evt;
                }
                setTouched({ ...touched, [name]: true });
              };

              const handleSelect = name => item => {
                handleTouched(name);
                if (name === LOCATION) {
                  setFieldValue(WARD, []);
                  setWardList(get(item, 'wards', []));
                }
                setFieldValue(name, item);
              };

              return (
                <form onSubmit={handleSubmit} className={s.form}>
                  <HomeGrid fluid justify="center" spacing={2}>
                    {formChunks.map(inputs => (
                      <HomeGrid key={get(inputs, '0.label')}>
                        {inputs.map(field => {
                          return (
                            <HomeInput
                              label={field.label}
                              type={field.type}
                              key={field.name}
                              id={field.name}
                              name={field.name}
                              maxLength={field.length}
                              onChange={handleChange}
                              errors={errors}
                              onBlur={handleTouched}
                              touched={touched}
                              list={sanitizedBag[field.name]}
                              onSelect={handleSelect(field.tag)}
                              values={values}
                              reset={isReset}
                            />
                          );
                        })}
                      </HomeGrid>
                    ))}
                  </HomeGrid>
                  <HomeSubmit valid={dirty && isValid} label={ctaLabel} />
                </form>
              );
            }}
          </Formik>
        </HomeGrid>
      </HomeGrid>
    </>
  );
};

Share.propTypes = {
  onCreateItem: func.isRequired,
  onUpdateItem: func.isRequired,
  onUpload: func.isRequired,
  onResetFile: func.isRequired,
  onCancel: func.isRequired,
  onReset: func.isRequired, // reset initialize item's value
  onResetItem: func.isRequired,
  onLoadItems: func.isRequired,
  initItem: objectOf(any),
  initSchemas: arrayOf(any),
  isError: bool,
  createdBranch: string,
  deletedBranch: string,
  fields: arrayOf(object).isRequired,
  token: string.isRequired,
  user: string.isRequired,
  schemas: objectOf(any),
  dataBag: objectOf(any),
};

Share.defaultProps = {
  createdBranch: '',
  deletedBranch: '',
  initSchemas: [],
  isError: false,
  initItem: {},
  schemas: {},
  dataBag: {},
};

export default connect(brandProps.mapStateToProps)(Share);
