import React, { useState, useEffect, useRef } from 'react';
import { objectOf, any, func, string } from 'prop-types';
import get from 'lodash/get';
import Share from './branch/Share';
import HomeModal from '../HomeModal';
import List from './branch/List';
import MESSAGES from '../../constants/messages';
import FORM from '../../constants/form';
import { branches as schemas } from '../../utils/schemas';
import HomeGrid from '../HomeGrid';
import { scrollTopView } from '../../utils/browser';
import LAYOUT from '../../constants/layout';

const Branch = ({
  user,
  token,
  dataBag,
  branches,
  onCancel,
  onLoadItems,
  onCreateItem,
  onUpdateItem,
  onDeleteItem,
  onResetItem,
  onUpload,
  onResetFile,
  onLoadParents,
}) => {
  const { BRANCH, INIT_SCHEMAS } = FORM;
  const [detail, setDetail] = useState({});
  const [configsBag, setConfigsBag] = useState({});
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [toggleDeletion, setToggleDeletion] = useState(false);
  const [deletionId, setDeletionId] = useState('');
  const topPage = useRef();

  const handleLoadItems = page => {
    onLoadItems(`?user=${user}&page=${page}`, token);
  };

  useEffect(() => {
    handleLoadItems(1);
    onLoadParents(`?user=${user}`, token);
  }, []);

  useEffect(() => {
    const count = get(branches, 'count', 0);
    setTotal(count / LAYOUT.ADMIN_PAGE_ITEM);
    setList(get(branches, 'branches', []));
  }, [branches]);

  useEffect(() => {
    setConfigsBag(dataBag);
  }, [dataBag]);

  const handleOpenUpdate = item => () => {
    setDetail(item);
    scrollTopView(topPage);
  };

  const handleToggleDeletion = () => {
    setToggleDeletion(!toggleDeletion);
  };

  const handleDelete = id => {
    onDeleteItem(id, token);
    scrollTopView(topPage);
  };

  const promptDeletion = id => () => {
    setToggleDeletion(!toggleDeletion);
    setDeletionId(id);
  };

  const confirmDeletion = () => {
    handleDelete(deletionId);
    handleToggleDeletion();
  };

  return (
    <HomeGrid fluid direction="column" ref={topPage}>
      <HomeModal
        isOpen={toggleDeletion}
        title={MESSAGES.CREATE_BRANCH.PROMPT_DEL_TITLE}
        message={MESSAGES.CREATE_BRANCH.PROMPT_DEL_MSG}
        onConfirm={confirmDeletion}
        onClose={handleToggleDeletion}
      />
      <Share
        token={token}
        user={user}
        fields={BRANCH}
        schemas={schemas}
        initSchemas={INIT_SCHEMAS.BRANCH}
        initItem={detail}
        dataBag={configsBag}
        onCancel={onCancel}
        onLoadItems={handleLoadItems}
        onCreateItem={onCreateItem}
        onUpdateItem={onUpdateItem}
        onResetItem={onResetItem}
        onUpdate={handleOpenUpdate}
        onUpload={onUpload}
        onResetFile={onResetFile}
        onReset={setDetail}
      />
      <List
        list={list}
        onUpdate={handleOpenUpdate}
        onDelete={promptDeletion}
        total={total}
        onLoadItems={handleLoadItems}
      />
    </HomeGrid>
  );
};

Branch.propTypes = {
  dataBag: objectOf(any),
  branches: objectOf(any),
  token: string.isRequired,
  user: string.isRequired,
  onCancel: func.isRequired,
  onLoadItems: func.isRequired,
  onCreateItem: func.isRequired,
  onUpdateItem: func.isRequired,
  onDeleteItem: func.isRequired,
  onUpload: func.isRequired,
  onResetFile: func.isRequired,
  onResetItem: func.isRequired,
  onLoadParents: func.isRequired,
};

Branch.defaultProps = {
  dataBag: {},
  branches: {},
};

export default Branch;
