import React, { useState, useEffect, useContext } from 'react';
import { string, func, objectOf, any } from 'prop-types';
import Fade from '@material-ui/core/Fade';
import DealList from './dealList';
import Detail from './deal/Detail';
import Share from './deal/Share';
import HomeModal from '../HomeModal';
import AdminContext from './AdminContext';
import TIME from '../../constants/time';
import MESSAGES from '../../constants/messages';

const Deal = ({
  token,
  user,
  onDeleteDeal,
  onFetchDetail,
  onUpdate,
  onCreate,
  onUpload,
  onResetDeal,
  onResetFile,
  dataBag,
}) => {
  const [viewDetail, setViewDetail] = useState(false);
  const [updateDeal, setUpdateDeal] = useState(false);
  const [copyDeal, setCopyDeal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deletingDealId, setDeletingDealId] = useState('');
  const [detail, setDetail] = useState({});
  const [configsBag, setConfigsBag] = useState({});
  const {
    deals,
    onFetchDeals,
    onSearch,
    onSetSearchText,
    adminSearchText,
  } = useContext(AdminContext);

  useEffect(() => {
    return () => onSetSearchText('');
  }, []);

  useEffect(() => {
    setConfigsBag(dataBag);
  }, [dataBag]);

  const handleViewDetail = id => {
    setViewDetail(true);
    onFetchDetail(id);
    onSetSearchText('');
  };

  const handleCloseDetail = () => {
    setViewDetail(false);
    setUpdateDeal(false);
    setCopyDeal(false);
  };

  const handleUpdateDeal = deal => () => {
    setUpdateDeal(true);
    setViewDetail(false);
    setCopyDeal(false);
    setDetail(deal);
  };

  const handleCopyDeal = deal => () => {
    setCopyDeal(true);
    setViewDetail(false);
    setUpdateDeal(false);
    setDetail({
      ...deal,
      id: '',
    });
  };

  const handlePromptDeletion = id => {
    setDeletingDealId(id);
    setDeleteModal(true);
  };

  const handleClosePromptModal = () => {
    setDeletingDealId('');
    setDeleteModal(false);
  };

  const handleConfirmDeletion = () => {
    onDeleteDeal(deletingDealId, token);
    setDeleteModal(false);
  };

  const showAll = !viewDetail && !(updateDeal || copyDeal);
  const showDetail = viewDetail && !(updateDeal || copyDeal);
  const showUpdate = !(viewDetail || copyDeal) && updateDeal;
  const showCopy = !(viewDetail || updateDeal) && copyDeal;

  return (
    <Fade in timeout={TIME.EFFECT_TIMEOUT}>
      <>
        <HomeModal
          isOpen={showDeleteModal}
          title={MESSAGES.DELETE.TITLE}
          message={MESSAGES.DELETE.PROMPT}
          onConfirm={handleConfirmDeletion}
          onClose={handleClosePromptModal}
        />
        {showAll && (
          <DealList
            user={user}
            token={token}
            deals={deals}
            onSearch={onSearch}
            searchText={adminSearchText}
            onView={handleViewDetail}
            onFetchDeals={onFetchDeals}
            onSetSearchText={onSetSearchText}
          />
        )}
        {showDetail && (
          <Detail
            token={token}
            onClose={handleCloseDetail}
            onUpdate={handleUpdateDeal}
            onCopy={handleCopyDeal}
            onDelete={handlePromptDeletion}
          />
        )}
        {showUpdate && (
          <Share
            title={MESSAGES.UPDATE.TITLE}
            user={user}
            token={token}
            detail={detail}
            onCancel={handleCloseDetail}
            dataBag={configsBag}
            onUpload={onUpload}
            onSubmit={onUpdate}
            onResetDeal={onResetDeal}
            onResetFile={onResetFile}
          />
        )}
        {showCopy && (
          <Share
            title={MESSAGES.COPY.TITLE}
            user={user}
            token={token}
            detail={detail}
            onCancel={handleCloseDetail}
            dataBag={configsBag}
            onUpload={onUpload}
            onSubmit={onCreate}
            onResetDeal={onResetDeal}
            onResetFile={onResetFile}
          />
        )}
      </>
    </Fade>
  );
};

Deal.propTypes = {
  token: string.isRequired,
  user: string.isRequired,
  onDeleteDeal: func.isRequired,
  onFetchDetail: func.isRequired,
  onUpdate: func.isRequired,
  onCreate: func.isRequired,
  onUpload: func.isRequired,
  onResetDeal: func.isRequired,
  onResetFile: func.isRequired,
  dataBag: objectOf(any),
};

Deal.defaultProps = {
  dataBag: {},
};

export default Deal;
