import React from 'react';
import Loading from '../components/loading';
import Error from '../components/error';
import Dashboard from '../components/admin/dashboard';
import Layout from '../components/layout';

const DetailPage = () => {
  return (
    <>
      <Layout>
        <Dashboard />
      </Layout>
      <Loading />
      <Error />
    </>
  );
};

export default DetailPage;
