import React, { useEffect, useState } from 'react';
import { func, number, objectOf, any } from 'prop-types';
import noop from 'lodash/noop';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import ILeft from '../svgs/ic_left_red.svg';
import IRight from '../svgs/ic_right.svg';
import HomeGrid from './HomeGrid';
import { scrollTopView } from '../utils/browser';
import LAYOUT from '../constants/layout';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: spacing(0),
    width: 0,
    transition: LAYOUT.TRANSITION_EASING,
    padding: 'unset',
  },
  showRoot: {
    width: '100%',
    padding: spacing(2, 0, 3),
  },
  paging: {
    display: 'flex',
    width: spacing(20),
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    visibility: 'hidden',
  },
  showIcon: {
    visibility: 'visible',
    cursor: 'pointer',
  },
  page: {
    width: spacing(3.5),
    color: palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  pageActive: {
    color: palette.primary.main,
  },
  pageHidden: {
    color: 'transparent',
  },
}));

const Pagination = ({ total, range, onSelect, topRef }) => {
  const s = useStyles();
  const [list, setList] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [cIndex, setCIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [showIconLeft, setShowIconLeft] = useState(true);
  const [showIconRight, setShowIconRight] = useState(true);
  const [adjRange, setAdjRange] = useState(range);

  const isNext = (index, max, piece) => index < max / piece - 1;
  const singlePage = list[0] && list[0].length === 1;

  useEffect(() => {
    if (total > 0) {
      const totalCeil = Math.ceil(total / 1);
      const aRange = totalCeil < adjRange ? totalCeil : range;
      setShowPagination(true);
      setList(
        chunk(
          Array.from({ length: totalCeil }, (_, ind) => ind + 1),
          aRange,
        ),
      );
      setShowIconRight(isNext(cIndex, totalCeil, aRange));
      setAdjRange(aRange);
      setPage(1);
      setCIndex(0);
    } else {
      setShowPagination(false);
    }
  }, [total]);

  useEffect(() => {
    setShowIconRight(isNext(cIndex, total, adjRange));
    setShowIconLeft(cIndex > 0);
  }, [cIndex]);

  const handleSelectPage = item => () => {
    if (topRef) {
      scrollTopView(topRef);
    }
    onSelect(item);
    setPage(item);
  };

  const handlePrev = () => {
    const isLeft = cIndex > 0;
    if (isLeft) {
      setCIndex(cIndex - 1);
      const prevPage = get(list[cIndex - 1], '0');
      handleSelectPage(prevPage)();
    }
  };

  const handleNext = () => {
    if (isNext(cIndex, total, adjRange)) {
      setCIndex(cIndex + 1);
      const nextPage = get(list[cIndex + 1], '0');
      handleSelectPage(nextPage)();
    }
  };

  return (
    <HomeGrid
      fluid
      alignItems="center"
      justify="center"
      className={classNames(s.root, showPagination && s.showRoot)}
    >
      <ILeft
        className={classNames(s.icon, showIconLeft && s.showIcon)}
        onClick={handlePrev}
      />
      <HomeGrid className={s.paging}>
        {get(list, cIndex, []).map(item => {
          const stress = page === item;
          return (
            <Typo
              key={item}
              onClick={handleSelectPage(item)}
              className={classNames(
                s.page,
                stress && s.pageActive,
                singlePage && s.pageHidden,
              )}
            >
              {item}
            </Typo>
          );
        })}
      </HomeGrid>
      <IRight
        className={classNames(s.icon, showIconRight && s.showIcon)}
        onClick={handleNext}
      />
    </HomeGrid>
  );
};

Pagination.propTypes = {
  total: number.isRequired,
  range: number,
  onSelect: func,
  topRef: objectOf(any),
};

Pagination.defaultProps = {
  range: 3,
  onSelect: noop,
  topRef: null,
};

export default Pagination;
