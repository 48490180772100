/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { func, string, objectOf, arrayOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HomeGrid from '../../HomeGrid';
import HomeInput from '../../HomeInput';
import HomeModal from '../../HomeModal';
import Title from '../Title';
import HomeSubmit from '../../HomeSubmit';
import { deal } from '../../../utils/schemas';
import FORM from '../../../constants/form';
import CTA from '../../../constants/cta';
import MESSAGE from '../../../constants/messages';
import { FILTER_KEY } from '../../../constants/filters';
import { sanitizedDeal } from '../../../utils/sanitizeFormValues';
import { formData } from '../../../utils/file';
import { createDealProps } from '../props';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(0, 0, 2),
    marginBottom: spacing(5),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const Create = ({
  token,
  user,
  onCancel,
  dataBag,
  onCreate,
  createdId,
  fileUpload,
  onUpload,
  onResetDeal,
  onResetFile,
  onLoadBrands,
}) => {
  const s = useStyles();
  const { CREATE_DEAL, FIELD_CHUNK, FIELD_NAMES } = FORM;
  const { BRAND, BRANCH } = FILTER_KEY;
  const { PICTURE, QR, DEAL } = FIELD_NAMES;
  const formChunks = chunk(CREATE_DEAL, FIELD_CHUNK);
  const [branchList, setBranchList] = useState([]);
  const [sanitizedBag, setSanitizedBag] = useState({});
  const [picture, setPicture] = useState(null);
  const [qr, setQr] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
    if (toggleModal) {
      onCancel();
    }
  };

  useEffect(() => {
    onLoadBrands(`?user=${user}`, token);
  }, []);

  useEffect(() => {
    setSanitizedBag({
      ...dataBag,
      [BRANCH]: branchList,
    });
  }, [branchList, dataBag]);

  useEffect(() => {
    if (createdId) {
      if (picture) {
        const uploadPix = formData(picture, createdId, DEAL, PICTURE);
        onUpload(uploadPix, token);
      }
      if (qr) {
        const uploadQR = formData(qr, createdId, DEAL, QR);
        onUpload(uploadQR, token);
      }
    }
  }, [createdId]);

  useEffect(() => {
    if (!isEmpty(fileUpload) && createdId) {
      handleToggleModal();
      onResetFile();
      onResetDeal();
    }
  }, [fileUpload]);

  const onSubmit = values => {
    setPicture(get(values, PICTURE, null));
    setQr(get(values, QR, null));
    onCreate(
      sanitizedDeal({
        ...values,
        user,
      }),
      token,
    );
  };

  return (
    <HomeGrid fluid direction="column" className={s.root}>
      <HomeModal
        isOpen={toggleModal}
        onClose={handleToggleModal}
        message={MESSAGE.CREATE.CONTENT}
      />
      <Title title={MESSAGE.CREATE.HEADING} onClose={onCancel} />
      <HomeGrid fluid direction="column" justify="center" alignItems="center">
        <Formik
          initialValues={{}}
          validateOnBlur
          validateOnMount
          validationSchema={deal}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            setTouched,
            setFieldValue,
            touched,
            isValid,
            errors,
            dirty,
            values,
          }) => {
            const handleTouched = evt => {
              let name;
              if (evt.preventDefault) {
                evt.preventDefault();
                name = get(evt, 'target.name');
              } else {
                name = evt;
              }
              setTouched({ ...touched, [name]: true });
            };

            const handleSelectItem = name => item => {
              handleTouched(name);
              if (name === BRAND) {
                setFieldValue(BRANCH, []);
                setBranchList(get(item, 'branches', []));
              }
              setFieldValue(name, item);
            };

            return (
              <form onSubmit={handleSubmit} className={s.form}>
                <HomeGrid fluid justify="center" spacing={2}>
                  {formChunks.map(fields => (
                    <HomeGrid key={get(fields, '0.label')}>
                      {fields.map(field => (
                        <HomeInput
                          key={field.name}
                          id={field.name}
                          type={field.type}
                          label={field.label}
                          name={field.name}
                          maxLength={field.length}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                          list={sanitizedBag[field.tag]}
                          multiSelect={field.multiSelect}
                          onBlur={handleTouched}
                          onSelect={handleSelectItem(field.tag)}
                        />
                      ))}
                    </HomeGrid>
                  ))}
                </HomeGrid>
                <HomeGrid fluid justify="center">
                  <HomeSubmit label={CTA.LABELS.CREATE} valid={dirty && isValid} />
                </HomeGrid>
              </form>
            );
          }}
        </Formik>
      </HomeGrid>
    </HomeGrid>
  );
};

Create.propTypes = {
  onCancel: func.isRequired,
  onCreate: func.isRequired,
  onUpload: func.isRequired,
  onResetDeal: func.isRequired,
  onResetFile: func.isRequired,
  onLoadBrands: func.isRequired,
  token: string.isRequired,
  user: string.isRequired,
  dataBag: objectOf(any),
  createdId: string,
  fileUpload: arrayOf(any),
};

Create.defaultProps = {
  dataBag: {},
  createdId: '',
  fileUpload: [],
};

export default connect(createDealProps.mapStateToProps)(Create);
