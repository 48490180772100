import React, { useState } from 'react';
import { string, func } from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import noop from 'lodash/noop';
import Typo from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import IClear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';
import HomeGrid from '../HomeGrid';
import LAYOUT from '../../constants/layout';
import COLORS from '../../constants/colors';
import TIME from '../../constants/time';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: spacing(6),
    height: spacing(LAYOUT.ADMIN_TITLE_HEIGHT),
    marginBottom: spacing(2),
    background: COLORS.LINEAR_BG,
    padding: spacing(1, 1.5),
    textTransform: 'uppercase',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    minWidth: spacing(30),
    background: palette.background.paper,
    padding: spacing(0.5, 1),
    borderRadius: shape.round,
  },
  search: {
    padding: spacing(0.25, 1),
    outline: 'none',
    border: 'none',
    width: '85%',
  },
  icon: {
    width: spacing(3),
    height: spacing(3),
    fill: palette.primary.main,
  },
  iClear: {
    cursor: 'pointer',
  },
  button: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
}));

const Title = ({ title, onClose, onSearch, onClear }) => {
  const s = useStyles();
  const [searchText, setSearchText] = useState('');

  const handleSearch = evt => {
    if (evt) {
      evt.preventDefault();
    }
    const {
      target: { value },
    } = evt;
    setSearchText(value);
  };

  const handleCloseSearch = () => {
    setSearchText('');
    onClear();
  };

  const handleSubmitSearch = evt => {
    if (evt) {
      evt.preventDefault();
    }
    onSearch(searchText);
  };

  return (
    <HomeGrid className={s.root}>
      <Grow in timeout={TIME.EFFECT_TIMEOUT}>
        <Typo variant="subtitle2">{title}</Typo>
      </Grow>
      {onClose && (
        <IconButton className={s.button} onClick={onClose}>
          <IClear className={s.icon} />
        </IconButton>
      )}
      {onSearch && (
        <form onSubmit={handleSubmitSearch}>
          <HomeGrid className={s.searchBox}>
            <Search className={s.icon} />
            <input
              placeholder={LAYOUT.SEARCH_PLACEHOLDER}
              className={s.search}
              value={searchText}
              onChange={handleSearch}
            />
            {searchText && (
              <IClear
                className={classNames(s.icon, s.iClear)}
                onClick={handleCloseSearch}
              />
            )}
          </HomeGrid>
        </form>
      )}
    </HomeGrid>
  );
};

Title.propTypes = {
  title: string.isRequired,
  onClose: func,
  onSearch: func,
  onClear: func,
};

Title.defaultProps = {
  onClose: null,
  onSearch: null,
  onClear: noop,
};

export default Title;
