import React, { useEffect, useState } from 'react';
import { arrayOf, any, func, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import Create from '@material-ui/icons/Create';
import DeleteForever from '@material-ui/icons/DeleteForever';
import CardMember from '@material-ui/icons/CardMembership';
import Place from '@material-ui/icons/Room';
import Tooltip from '@material-ui/core/Tooltip';
import CustomImage from '../../customImage';
import HomeGrid from '../../HomeGrid';
import Pagination from '../../Pagination';
import resolveImageUrl from '../../../utils/image';
import COLORS from '../../../constants/colors';
import FORM from '../../../constants/form';
import MESSAGES from '../../../constants/messages';
import LAYOUT from '../../../constants/layout';

const useStyles = makeStyles(
  ({ spacing, palette, typography, transitions, shape, zIndex }) => ({
    root: {
      margin: spacing(0, 0, 2),
      padding: spacing(2, 2, 0),
    },
    tab: {
      userSelect: 'none',
      padding: spacing(1, 0),
      marginBottom: spacing(2),
      transition: transitions.easing.custom,
      borderBottom: `${spacing(1)}px solid ${palette.primary.dark}`,
    },
    branch: {
      position: 'relative',
      margin: spacing(1),
      background: palette.background.paper,
      boxShadow: COLORS.SHADOW_STANDARD,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: spacing(1),
      minWidth: spacing(35),
      width: '31%',
      '&:hover': {
        '& > svg': {
          visibility: 'visible',
        },
      },
    },
    logoWrap: {
      width: spacing(10),
      minWidth: spacing(10),
      height: spacing(10),
      margin: spacing(1, 2),
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '57%',
    },
    text: {
      width: '83%',
      fontSize: typography.body3.fontSize,
    },
    name: {
      width: '100%',
      fontWeight: typography.fontWeightBold,
    },
    address: {
      width: '100%',
      fontSize: typography.body3.fontSize,
    },
    icon: {
      width: spacing(2),
      height: spacing(2),
      margin: spacing(0.5),
    },
    icons: {
      visibility: 'hidden',
      position: 'absolute',
      width: spacing(3),
      height: spacing(3),
      borderRadius: shape.round,
      fill: palette.primary.light,
      padding: spacing(0.25),
      transition: transitions.easing.custom,
      top: spacing(1),
      background: palette.grey[50],
      zIndex: zIndex.tooltip,
      '&:hover': {
        fill: palette.primary.dark,
        cursor: 'pointer',
        transform: 'scale(1.2)',
      },
    },
    updateIcon: {
      right: spacing(5),
    },
    deleteIcon: {
      right: spacing(1),
    },
  }),
);

const List = ({ list, onUpdate, onDelete, total, onLoadItems }) => {
  const s = useStyles();
  const { FIELD_NAMES } = FORM;
  const [count, setCount] = useState(total);

  useEffect(() => {
    setCount(total);
  }, [total]);

  return (
    <HomeGrid fluid direction="column" className={s.root}>
      <HomeGrid fluid className={s.tab}>
        <Typo variant="subtitle2">{MESSAGES.CREATE_BRANCH.LIST_TITLE}</Typo>
      </HomeGrid>
      <HomeGrid fluid>
        {list.map(item => {
          const id = get(item, 'id');
          const name = get(item, FORM.NAME);
          const address = get(item, FIELD_NAMES.ADDRESS);
          const logo =
            get(item, `${FIELD_NAMES.PICTURE}.url`, undefined) ||
            get(item, `${FIELD_NAMES.LOGO}.url`);
          const location = get(item, FIELD_NAMES.LOCATION);
          const ward = get(item, FIELD_NAMES.WARD);
          const brand = get(item, FIELD_NAMES.BRAND);

          return (
            <HomeGrid key={id} className={s.branch}>
              <Create
                className={`${s.icons} ${s.updateIcon}`}
                onClick={onUpdate(item)}
              />
              <DeleteForever
                className={`${s.icons} ${s.deleteIcon}`}
                onClick={onDelete(id)}
              />
              <HomeGrid fluid alignItems="center">
                <HomeGrid className={s.logoWrap}>
                  <CustomImage
                    logo
                    src={resolveImageUrl(logo)}
                    alt={name}
                    className={s.logo}
                  />
                </HomeGrid>
                <HomeGrid className={s.info}>
                  <Tooltip title={name} placement="bottom-start">
                    <Typo className={classNames(s.name, s.text)} noWrap>
                      {name}
                    </Typo>
                  </Tooltip>
                  <HomeGrid fluid className={s.item} alignItems="center">
                    <CardMember className={s.icon} />
                    <Tooltip title={brand} placement="top">
                      <Typo className={s.text} noWrap>
                        {brand}
                      </Typo>
                    </Tooltip>
                  </HomeGrid>
                  <HomeGrid fluid className={s.item} alignItems="center">
                    <Place className={s.icon} />
                    <Typo className={s.text}>
                      {ward ? `${ward},` : ''} {location}
                    </Typo>
                  </HomeGrid>
                </HomeGrid>
              </HomeGrid>
              <Tooltip title={address} placement="bottom-start">
                <Typo className={s.address} noWrap>
                  {address}
                </Typo>
              </Tooltip>
            </HomeGrid>
          );
        })}
      </HomeGrid>
      <Pagination
        total={count}
        range={LAYOUT.SHOW_ITEM_SEARCH}
        onSelect={onLoadItems}
      />
    </HomeGrid>
  );
};

List.propTypes = {
  list: arrayOf(any),
  onUpdate: func.isRequired,
  onDelete: func.isRequired,
  onLoadItems: func.isRequired,
  total: number,
};

List.defaultProps = {
  list: [],
  total: 0,
};

export default List;
