import React, { useState, useEffect } from 'react';
import { arrayOf, any, func, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import Create from '@material-ui/icons/Create';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';
import CustomImage from '../../customImage';
import HomeGrid from '../../HomeGrid';
import Pagination from '../../Pagination';
import resolveImageUrl from '../../../utils/image';
import COLORS from '../../../constants/colors';
import MESSAGES from '../../../constants/messages';
import LAYOUT from '../../../constants/layout';

const useStyles = makeStyles(
  ({ spacing, palette, typography, transitions, shape, zIndex }) => ({
    root: {
      margin: spacing(0, 0, 2),
      padding: spacing(2, 2, 0),
    },
    tab: {
      userSelect: 'none',
      padding: spacing(1, 0),
      marginBottom: spacing(2),
      transition: transitions.easing.custom,
      borderBottom: `${spacing(1)}px solid ${palette.primary.dark}`,
    },
    brand: {
      position: 'relative',
      margin: spacing(1),
      background: palette.background.paper,
      boxShadow: COLORS.SHADOW_STANDARD,
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1),
      minWidth: spacing(35),
      width: '31%',
      '&:hover': {
        '& > svg': {
          visibility: 'visible',
        },
      },
    },
    logoWrap: {
      width: spacing(10),
      minWidth: spacing(10),
      height: spacing(10),
      margin: spacing(1, 2),
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '60%',
    },
    text: {
      width: '100%',
      fontSize: typography.body3.fontSize,
    },
    name: {
      fontWeight: typography.fontWeightBold,
    },
    icons: {
      visibility: 'hidden',
      position: 'absolute',
      width: spacing(3),
      height: spacing(3),
      borderRadius: shape.round,
      fill: palette.primary.light,
      padding: spacing(0.25),
      transition: transitions.easing.custom,
      top: spacing(1),
      background: palette.grey[50],
      zIndex: zIndex.tooltip,
      '&:hover': {
        fill: palette.primary.dark,
        cursor: 'pointer',
        transform: 'scale(1.2)',
      },
    },
    updateIcon: {
      right: spacing(5),
    },
    deleteIcon: {
      right: spacing(1),
    },
  }),
);

const List = ({ list, onUpdate, onDelete, total, onLoadItems }) => {
  const s = useStyles();
  const [count, setCount] = useState(total);

  useEffect(() => {
    setCount(total);
  }, [total]);

  return (
    <HomeGrid fluid direction="column" className={s.root}>
      <HomeGrid fluid className={s.tab}>
        <Typo variant="subtitle2">{MESSAGES.CREATE_BRAND.LIST_TITLE}</Typo>
      </HomeGrid>
      <HomeGrid fluid>
        {list.map(item => {
          const id = get(item, 'id');
          const name = get(item, 'name');
          const website = get(item, 'website');
          const logo = get(item, 'logo.url');
          const branchCount = get(item, 'branches', []).length;

          return (
            <HomeGrid key={id} className={s.brand}>
              <Create
                className={`${s.icons} ${s.updateIcon}`}
                onClick={onUpdate(item)}
              />
              <DeleteForever
                className={`${s.icons} ${s.deleteIcon}`}
                onClick={onDelete(id)}
              />
              <HomeGrid className={s.logoWrap}>
                <CustomImage
                  logo
                  src={resolveImageUrl(logo)}
                  alt={name}
                  className={s.logo}
                />
              </HomeGrid>
              <HomeGrid className={s.info}>
                <Tooltip title={name} placement="bottom-start">
                  <Typo className={classNames(s.name, s.text)} noWrap>
                    {name}
                  </Typo>
                </Tooltip>
                {website && (
                  <Tooltip title={website} placement="bottom-start">
                    <Typo className={s.text} noWrap>
                      {website}
                    </Typo>
                  </Tooltip>
                )}
                <Typo className={s.text}>
                  {MESSAGES.CREATE_BRAND.COUNT} {branchCount}
                </Typo>
              </HomeGrid>
            </HomeGrid>
          );
        })}
        <Pagination
          total={count}
          onSelect={onLoadItems}
          range={LAYOUT.SHOW_ITEM_SEARCH}
        />
      </HomeGrid>
    </HomeGrid>
  );
};

List.propTypes = {
  list: arrayOf(any),
  onUpdate: func.isRequired,
  onLoadItems: func.isRequired,
  onDelete: func.isRequired,
  total: number,
};

List.defaultProps = {
  list: [],
  total: 0,
};

export default List;
