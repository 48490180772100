/* eslint-disable no-use-before-define */

export default {
  KEYS: {
    ALL_DEALS: 'ALL_DEALS',
    CREATE_BRAND: 'CREATE_BRAND',
    CREATE_BRANCH: 'CREATE_BRANCH',
    CREATE_DEAL: 'CREATE_DEAL',
    SIGN_OUT: 'SIGN_OUT',
  },
  get LIST() {
    return [
      {
        label: 'Danh sách Ưu đãi',
        key: this.KEYS.ALL_DEALS,
      },
      {
        label: 'Tạo Thương hiệu',
        key: this.KEYS.CREATE_BRAND,
      },
      {
        label: 'Tạo Chi nhánh',
        key: this.KEYS.CREATE_BRANCH,
      },
      {
        label: 'Tạo Ưu đãi',
        key: this.KEYS.CREATE_DEAL,
      },
      {
        label: 'Đăng xuất',
        key: this.KEYS.SIGN_OUT,
      },
    ];
  },
};
