import React, { useState, useEffect, useRef } from 'react';
import { string, func, objectOf, any } from 'prop-types';
import get from 'lodash/get';
import Share from './brand/Share';
import List from './brand/List';
import HomeModal from '../HomeModal';
import MESSAGES from '../../constants/messages';
import HomeGrid from '../HomeGrid';
import FORM from '../../constants/form';
import LAYOUT from '../../constants/layout';
import { scrollTopView } from '../../utils/browser';

const Brand = ({
  token,
  user,
  brands,
  onCancel,
  onUpload,
  onResetFile,
  onCreateBrand,
  onDeleteBrand,
  onUpdateBrand,
  onResetBrand,
  onLoadBrands,
}) => {
  const { BRAND } = FORM;
  const [detail, setDetail] = useState({});
  const [brandList, setBrandList] = useState([]);
  const [total, setTotal] = useState(0);
  const [toggleDeletion, setToggleDeletion] = useState(false);
  const [deletionId, setDeletionId] = useState('');
  const topPage = useRef();

  const handleLoadBrands = item => {
    onLoadBrands(`?user=${user}&page=${item}`, token);
  };

  useEffect(() => {
    handleLoadBrands(1);
  }, []);

  useEffect(() => {
    setBrandList(get(brands, 'brands', []));
    setTotal(get(brands, 'count', 0) / LAYOUT.ADMIN_PAGE_ITEM);
  }, [brands]);

  const handleOpenUpdate = item => () => {
    setDetail(item);
    scrollTopView(topPage);
  };

  const handleToggleDeletion = () => {
    setToggleDeletion(!toggleDeletion);
  };

  const handleDelete = id => {
    onDeleteBrand(id, token);
    scrollTopView(topPage);
  };

  const promptDeletion = id => () => {
    setToggleDeletion(!toggleDeletion);
    setDeletionId(id);
  };

  const confirmDeletion = () => {
    handleDelete(deletionId);
    handleToggleDeletion();
  };

  return (
    <HomeGrid fluid direction="column" ref={topPage}>
      <HomeModal
        isOpen={toggleDeletion}
        title={MESSAGES.CREATE_BRAND.PROMPT_DEL_TITLE}
        message={MESSAGES.CREATE_BRAND.PROMPT_DEL_MSG}
        onConfirm={confirmDeletion}
        onClose={handleToggleDeletion}
      />
      <Share
        token={token}
        user={user}
        fields={BRAND}
        initBrand={detail}
        onLoadBrands={handleLoadBrands}
        onCreateBrand={onCreateBrand}
        onUpdateBrand={onUpdateBrand}
        onCancel={onCancel}
        onUpdate={setDetail}
        onUpload={onUpload}
        onResetFile={onResetFile}
        onResetBrand={onResetBrand}
      />
      <List
        list={brandList}
        onDelete={promptDeletion}
        onUpdate={handleOpenUpdate}
        onLoadItems={handleLoadBrands}
        total={total}
      />
    </HomeGrid>
  );
};

Brand.propTypes = {
  token: string.isRequired,
  user: string.isRequired,
  brands: objectOf(any),
  onCreateBrand: func.isRequired,
  onUpdateBrand: func.isRequired,
  onDeleteBrand: func.isRequired,
  onResetBrand: func.isRequired,
  onCancel: func.isRequired,
  onUpload: func.isRequired,
  onResetFile: func.isRequired,
  onLoadBrands: func.isRequired,
};

Brand.defaultProps = {
  brands: {},
};

export default Brand;
