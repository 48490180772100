import React, { useState, useEffect } from 'react';
import { objectOf, any, func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from './navBar';
import SideBar from './sideBar';
import Deal from './deal';
import Brand from './Brand';
import Branch from './Branch';
import HomeGrid from '../HomeGrid';
import Footer from '../footer';
import Loading from '../loading';
import Create from './deal/Create';
import useConfigs from '../../hooks/useConfigs';
import AdminContext from './AdminContext';
import { dashboardProps } from './props';
import { FILTER_KEY } from '../../constants/filters';
import FORM from '../../constants/form';
import SIDE_BAR from '../../constants/sideBar';
import LAYOUT from '../../constants/layout';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    minHeight: '100vh',
  },
  content: {
    position: 'relative',
    width: '100%',
    maxWidth: spacing(180),
    background: COLORS.WHITE,
    margin: `${spacing(LAYOUT.ADMIN_BANNER_HEIGHT)}px auto 0`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  sideWrapper: {
    position: 'relative',
    minWidth: spacing(28),
    borderRight: `1px solid ${COLORS.LIGHT_GREY}`,
  },
}));

const Dashboard = ({
  isAuth,
  brands,
  branches,
  dealAdmin,
  adminSearchText,
  configs,
  userDetail,
  dispatchLogout,
  dispatchDeleteDeal,
  dispatchFetchDealDetail,
  dispatchCreateBrand,
  dispatchDeleteBrand,
  dispatchResetBrandData,
  dispatchUpdateBrand,
  dispatchGetBrands,
  dispatchGetBranches,
  dispatchCreateBranch,
  dispatchUpdateBranch,
  dispatchCreateDeal,
  dispatchUpdateDeal,
  dispatchUploadFile,
  dispatchResetFile,
  dispatchResetDeal,
  dispatchAdminDeal,
  dispatchAdminSearch,
  dispatchSetAdminSearchText,
  dispatchDeleteBranch,
}) => {
  const s = useStyles();
  const [token, setToken] = useState('');
  const [user, setUser] = useState('');
  const [dataBag, setDataBag] = useState({});
  const { TYPE, PRIORITY, METHOD, CATEGORY, BRAND, LOCATION } = FILTER_KEY;
  const { USERID, TOKEN } = FORM;
  const [sideBarKey, setSideBarKey] = useState(Object.keys(SIDE_BAR.KEYS)[0]);
  const [showComponent, setShowComponent] = useState({ [sideBarKey]: true });

  useConfigs();

  useEffect(() => {
    dispatchSetAdminSearchText('');
  }, []);

  useEffect(() => {
    const userJwt = get(userDetail, TOKEN);
    const userId = get(userDetail, USERID);
    if (userJwt) {
      setToken(userJwt);
      setUser(userId);
      dispatchGetBrands(`?user=${userId}`, userJwt);
    }
  }, [userDetail]);

  useEffect(() => {
    if (!isEmpty(configs)) {
      setDataBag({
        ...dataBag,
        ...pick(configs, [CATEGORY, TYPE, PRIORITY, METHOD, LOCATION]),
      });
    }
  }, [configs]);

  useEffect(() => {
    setDataBag({
      ...dataBag,
      [BRAND]: get(brands, 'brands', []),
    });
  }, [brands]);

  const handleLogout = () => {
    dispatchLogout();
  };

  const handleSideBarItem = key => {
    setSideBarKey(key);
    setShowComponent({ [key]: true });
  };
  const handleBackToAll = () => {
    setSideBarKey(SIDE_BAR.KEYS.ALL_DEALS);
    setShowComponent({ [SIDE_BAR.KEYS.ALL_DEALS]: true });
  };

  return (
    <>
      <HomeGrid fluid direction="column" justify="space-between" className={s.root}>
        {isAuth && (
          <>
            <HomeGrid className={s.top}>
              <HomeGrid>
                <NavBar userDetail={userDetail} />
              </HomeGrid>
              <HomeGrid className={s.content}>
                <aside className={s.sideWrapper}>
                  <SideBar
                    onSelect={handleSideBarItem}
                    onLogout={handleLogout}
                    sideBarKey={sideBarKey}
                  />
                </aside>
                {showComponent[SIDE_BAR.KEYS.ALL_DEALS] && (
                  <AdminContext.Provider
                    value={{
                      deals: dealAdmin,
                      onFetchDeals: dispatchAdminDeal,
                      onSearch: dispatchAdminSearch,
                      adminSearchText,
                      onSetSearchText: dispatchSetAdminSearchText,
                    }}
                  >
                    <Deal
                      token={token}
                      user={user}
                      dataBag={dataBag}
                      onDeleteDeal={dispatchDeleteDeal}
                      onFetchDetail={dispatchFetchDealDetail}
                      onUpdate={dispatchUpdateDeal}
                      onCreate={dispatchCreateDeal}
                      onUpload={dispatchUploadFile}
                      onResetDeal={dispatchResetDeal}
                      onResetFile={dispatchResetFile}
                    />
                  </AdminContext.Provider>
                )}
                {showComponent[SIDE_BAR.KEYS.CREATE_DEAL] && (
                  <Create
                    token={token}
                    user={user}
                    dataBag={dataBag}
                    onCreate={dispatchCreateDeal}
                    onResetDeal={dispatchResetDeal}
                    onCancel={handleBackToAll}
                    onUpload={dispatchUploadFile}
                    onResetFile={dispatchResetFile}
                    onLoadBrands={dispatchGetBrands}
                  />
                )}
                {showComponent[SIDE_BAR.KEYS.CREATE_BRAND] && (
                  <Brand
                    token={token}
                    user={user}
                    brands={brands}
                    onCancel={handleBackToAll}
                    onLoadBrands={dispatchGetBrands}
                    onCreateBrand={dispatchCreateBrand}
                    onDeleteBrand={dispatchDeleteBrand}
                    onUpdateBrand={dispatchUpdateBrand}
                    onResetBrand={dispatchResetBrandData}
                    onUpload={dispatchUploadFile}
                    onResetFile={dispatchResetFile}
                  />
                )}
                {showComponent[SIDE_BAR.KEYS.CREATE_BRANCH] && (
                  <Branch
                    token={token}
                    user={user}
                    branches={branches}
                    dataBag={pick(dataBag, [LOCATION, BRAND])}
                    onCancel={handleBackToAll}
                    onLoadItems={dispatchGetBranches}
                    onLoadParents={dispatchGetBrands}
                    onCreateItem={dispatchCreateBranch}
                    onUpdateItem={dispatchUpdateBranch}
                    onDeleteItem={dispatchDeleteBranch}
                    onResetItem={dispatchResetBrandData}
                    onUpload={dispatchUploadFile}
                    onResetFile={dispatchResetFile}
                  />
                )}
              </HomeGrid>
            </HomeGrid>
            <Footer />
          </>
        )}
      </HomeGrid>
      <Loading isLoading={!isAuth} />
    </>
  );
};

Dashboard.propTypes = {
  brands: objectOf(any),
  configs: objectOf(any),
  branches: objectOf(any),
  userDetail: objectOf(any),
  dealAdmin: objectOf(any),
  adminSearchText: string,
  isAuth: bool.isRequired,
  dispatchFetchDealDetail: func.isRequired,
  dispatchDeleteDeal: func.isRequired,
  dispatchLogout: func.isRequired,
  dispatchGetBrands: func.isRequired,
  dispatchCreateDeal: func.isRequired,
  dispatchUpdateDeal: func.isRequired,
  dispatchCreateBrand: func.isRequired,
  dispatchDeleteBrand: func.isRequired,
  dispatchDeleteBranch: func.isRequired,
  dispatchResetBrandData: func.isRequired,
  dispatchUpdateBrand: func.isRequired,
  dispatchUploadFile: func.isRequired,
  dispatchResetFile: func.isRequired,
  dispatchResetDeal: func.isRequired,
  dispatchAdminDeal: func.isRequired,
  dispatchAdminSearch: func.isRequired,
  dispatchSetAdminSearchText: func.isRequired,
  dispatchGetBranches: func.isRequired,
  dispatchCreateBranch: func.isRequired,
  dispatchUpdateBranch: func.isRequired,
};

Dashboard.defaultProps = {
  userDetail: {},
  configs: {},
  dealAdmin: {},
  adminSearchText: '',
  brands: {},
  branches: {},
};

export default connect(
  dashboardProps.mapStateToProps,
  dashboardProps.mapDispatchToProps,
)(Dashboard);
