/* eslint-disable no-undef */

import get from 'lodash/get';
import { uuid } from 'uuidv4';
import { sanitizeUrl } from './string';

export const formData = (file, refId, ref, field, path = 'files') => {
  const data = new FormData();
  const name = get(file, 'name', 'mono-file');
  data.set('files', file, `${refId}-${uuid()}-${sanitizeUrl(name)}`);
  data.set('refId', refId);
  data.set('ref', ref);
  data.set('path', path);
  data.set('field', field);
  data.set('source', 'users-permissions');
  return data;
};
