import get from 'lodash/get';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import FORM from '../constants/form';
import { FILTER_KEY } from '../constants/filters';

const { FIELD_NAMES } = FORM;
const { TYPE, BRANCH, METHOD, CATEGORY, BRAND, PRIORITY, PICTURE, QR } = FILTER_KEY;

export const brand = values => ({
  name: values[FIELD_NAMES.BRAND],
  website: values[FIELD_NAMES.WEBSITE],
});

export const branch = values => ({
  name: values[FIELD_NAMES.BRANCH],
  address: values[FIELD_NAMES.ADDRESS],
  brand: get(values[FIELD_NAMES.BRAND], 'id'),
  location: get(values[FIELD_NAMES.LOCATION], 'id'),
  ward: get(values[FIELD_NAMES.WARD], 'id'),
});

export const sanitizedDeal = values => {
  const types = compact(get(values, TYPE, []).map(item => get(item, 'id')));
  const methods = compact(get(values, METHOD, []).map(item => get(item, 'id')));
  const brandId = get(values, `${BRAND}.id`, null);
  const branches = compact(get(values, BRANCH, []).map(item => get(item, 'id')));
  const category = get(values, `${CATEGORY}.id`, null);
  const priority = get(values, `${PRIORITY}.id`, null);
  return {
    ...omit(values, [TYPE, METHOD, BRANCH, QR, PICTURE]),
    types,
    methods,
    brand: brandId,
    branches,
    category,
    priority,
    completed: true,
  };
};
