import React, { useState, useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typo from '@material-ui/core/Typography';
import ViewModule from '@material-ui/icons/ViewModule';
import CardMembership from '@material-ui/icons/CardMembership';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import AddLocation from '@material-ui/icons/AddLocation';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ROUTER from '../../constants/router';
import SIDE_BAR from '../../constants/sideBar';
import COLORS from '../../constants/colors';
import HomeGrid from '../HomeGrid';

const useStyles = makeStyles(({ spacing, palette, transitions }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    padding: spacing(2.5, 2),
    color: palette.secondary.main,
    transition: transitions.easing.custom,
    borderRight: `${spacing(1)}px solid transparent`,
    background: 'transparent',
    '&:hover': {
      background: palette.secondary.lighter,
      borderRight: `${spacing(1)}px solid ${palette.primary.main}`,
    },
  },
  iActive: {
    background: palette.secondary.lighter,
    borderRight: `${spacing(1)}px solid ${palette.primary.main}`,
  },
  text: {
    userSelect: 'none',
    position: 'relative',
  },
  tActive: {
    color: palette.secondary.dark,
  },
  icon: {
    fill: palette.primary.main,
    width: spacing(3),
    height: spacing(3),
    margin: spacing(0, 1.5),
  },
  block: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: COLORS.OVERLAY,
  },
}));
const icons = [ViewModule, CardMembership, AddLocation, CardGiftcard, ExitToApp];

const SideBar = ({ blocked, onSelect, onLogout, sideBarKey }) => {
  const s = useStyles();
  const [sideBar, setSideBar] = useState({ [sideBarKey]: true });

  useEffect(() => {
    setSideBar({ [sideBarKey]: true });
  }, [sideBarKey]);

  const handleSelectItem = key => () => {
    onSelect(key);
    setSideBar({ [key]: true });
  };
  const logout = () => {
    onLogout();
    navigate(`${ROUTER.ADMIN.LOGIN}`);
  };
  const sideBars = SIDE_BAR.LIST.map((item, index) => {
    const Icon = icons[index];
    return {
      ...item,
      icon: <Icon className={s.icon} />,
    };
  });

  return (
    <HomeGrid className={s.root}>
      {sideBars.map(bar => {
        const action =
          bar.key === SIDE_BAR.KEYS.SIGN_OUT ? logout : handleSelectItem(bar.key);
        const active = sideBar[bar.key];
        return (
          <HomeGrid
            key={bar.key}
            className={classNames(s.item, active && s.iActive)}
            onClick={action}
          >
            {bar.icon}
            <Typo className={classNames(s.text, active && s.tActive)}>{bar.label}</Typo>
          </HomeGrid>
        );
      })}
      {blocked && <HomeGrid className={s.block} />}
    </HomeGrid>
  );
};

SideBar.propTypes = {
  onSelect: func.isRequired,
  onLogout: func.isRequired,
  sideBarKey: string,
  blocked: bool,
};

SideBar.defaultProps = {
  sideBarKey: SIDE_BAR.KEYS.ALL_DEALS,
  blocked: false,
};

export default SideBar;
