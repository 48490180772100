import React, { useState, useEffect } from 'react';
import { objectOf, any, func } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Typo from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Create from '@material-ui/icons/Create';
import Description from '@material-ui/icons/Description';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { dealProps } from '../props';
import resolveImageUrl from '../../../utils/image';
import { trim } from '../../../utils/string';
import Loading from '../../loading';
import HomeModal from '../../HomeModal';
import CustomImage from '../../customImage';
import HomeGrid from '../../HomeGrid';
import CTA from '../../../constants/cta';
import TIME from '../../../constants/time';
import LAYOUT from '../../../constants/layout';
import COLORS from '../../../constants/colors';
import MESSAGES from '../../../constants/messages';
import FORM from '../../../constants/form';

const useStyles = makeStyles(
  ({ spacing, typography, palette, shape, transitions }) => ({
    root: {
      padding: spacing(0, 0, 3),
    },
    pageTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: spacing(LAYOUT.ADMIN_TITLE_HEIGHT),
      background: COLORS.LINEAR_BG,
    },
    icon: {
      width: spacing(2.5),
      height: spacing(2.5),
      transition: transitions.easing.custom,
      '&:hover': {
        transform: 'scale(1.3)',
      },
    },
    back: {
      '&:hover': {
        background: 'none',
        fill: palette.primary.main,
      },
    },
    picturesWrap: {
      width: '80%',
      height: spacing(50),
      overflow: 'hidden',
    },
    content: {
      width: '100%',
      position: 'relative',
      padding: spacing(2),
    },
    header: {
      margin: spacing(3, 0, 1),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: shape.rectRadius,
      boxShadow: COLORS.SHADOW_STANDARD,
    },
    logoWrap: {
      width: spacing(10),
      height: spacing(10),
      boxShadow: COLORS.SHADOW_STANDARD,
      borderRadius: shape.rectRadius,
      margin: spacing(0, 1),
      overflow: 'hidden',
      position: 'absolute',
      top: spacing(2),
      left: '50%',
      transform: 'translateX(-50%)',
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    qrWrap: {
      width: spacing(10),
      height: spacing(10),
    },
    info: {
      display: 'flex',
    },
    briefDesc: {
      display: 'flex',
      flexDirection: 'column',
      margin: spacing(2, 0),
    },
    branches: {
      display: 'flex',
    },
    label: {
      fontWeight: typography.fontWeightMedium,
    },
    richText: {
      width: '100%',
      fontSize: typography.body2.fontSize,
      outline: 'none',
      resize: 'none',
      border: 'none',
      background: 'transparent',
      minHeight: spacing(25),
      height: 'auto',
      fontFamily: typography.fontFamily,
      textAlign: 'justify',
    },
    textAreaInput: {
      color: palette.text.primary,
    },
    button: {
      margin: spacing(0, 2),
      fontSize: typography.body2.fontSize,
      padding: spacing(0.5),
      borderRadius: shape.round,
    },
    table: {
      borderCollapse: 'collapse',
    },
    tr: {
      borderBottom: `0.5px dashed ${COLORS.LIGHT_GREY}`,
      '&:hover': {
        background: COLORS.LINEAR_BG,
      },
    },
    td: {
      padding: spacing(1),
    },
  }),
);

const Detail = ({
  deal,
  onClose,
  onUpdate,
  onCopy,
  onDelete,
  deletedDeal,
  dispatchResetDeal,
}) => {
  const s = useStyles();
  const { CREATE_DEAL, FIELD_NAMES, FIELD_LABELS } = FORM;
  const { BRANCH, METHOD, TYPE, DEAL_DETAIL, UPDATED } = FIELD_NAMES;
  const [detail, setDetail] = useState({});
  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const detailText = get(detail, 'detail', '');
  const dealId = get(detail, 'id', '');
  const title = get(detail, 'title', '');
  const brandName = get(detail, 'brand.name', '');
  const brandLogo = get(detail, 'brand.logo.url', '');
  const pictures = get(detail, 'picture', [{ url: '' }]);

  useEffect(() => {
    setDetail(deal);
  }, [deal]);

  useEffect(() => {
    if (!isEmpty(deletedDeal)) {
      setDeletionSuccess(true);
    }
  }, [deletedDeal]);

  const handleDelete = id => () => {
    onDelete(id);
  };

  const handleCloseDeletionPopup = () => {
    onClose();
    dispatchResetDeal();
  };

  const fields = [
    ...CREATE_DEAL,
    {
      label: FIELD_LABELS.UPDATED,
      name: UPDATED,
    },
  ];

  return (
    <>
      <HomeModal
        isOpen={deletionSuccess}
        title={MESSAGES.DELETE.TITLE}
        message={`Ưu đãi "${title}" đã được xóa khỏi danh sách.`}
        onClose={handleCloseDeletionPopup}
      />
      <Fade in timeout={TIME.EFFECT_TIMEOUT}>
        <HomeGrid fluid direction="column" justify="space-evenly" className={s.root}>
          <HomeGrid className={s.pageTitle}>
            <HomeGrid fluid justify="space-between" alignItems="center">
              <HomeGrid>
                <HomeGrid fluid alignItems="center">
                  <IconButton href="" className={s.back} onClick={onClose}>
                    <ChevronLeft color="inherit" />
                  </IconButton>
                  <Tooltip title={title}>
                    <Typo variant="subtitle1" color="textPrimary" noWrap>
                      {trim(title, LAYOUT.CARD.TITLE_LENGTH_LARGE)}
                    </Typo>
                  </Tooltip>
                </HomeGrid>
              </HomeGrid>
              <HomeGrid>
                <HomeGrid fluid justify="space-evenly" alignItems="center">
                  <HomeGrid>
                    <Tooltip title={CTA.LABELS.UPDATE}>
                      <IconButton
                        href=""
                        onClick={onUpdate(detail)}
                        className={s.button}
                        color="secondary"
                      >
                        <Create className={s.icon} />
                      </IconButton>
                    </Tooltip>
                  </HomeGrid>
                  <HomeGrid>
                    <Tooltip title={CTA.LABELS.COPY}>
                      <IconButton
                        href=""
                        onClick={onCopy(detail)}
                        className={s.button}
                        color="secondary"
                      >
                        <Description className={s.icon} />
                      </IconButton>
                    </Tooltip>
                  </HomeGrid>
                  <HomeGrid>
                    <Tooltip title={CTA.LABELS.DELETE}>
                      <IconButton
                        href=""
                        color="primary"
                        onClick={handleDelete(dealId)}
                        className={s.button}
                      >
                        <DeleteForever className={s.icon} />
                      </IconButton>
                    </Tooltip>
                  </HomeGrid>
                </HomeGrid>
              </HomeGrid>
            </HomeGrid>
          </HomeGrid>
          <HomeGrid className={s.content}>
            <HomeGrid fluid direction="column" spacing={1} className={s.header}>
              <HomeGrid className={s.logoWrap}>
                <CustomImage
                  logo
                  src={resolveImageUrl(brandLogo)}
                  alt={brandName}
                  className={s.logo}
                />
              </HomeGrid>
              <HomeGrid className={s.picturesWrap}>
                <CustomImage
                  src={resolveImageUrl(get(pictures, 'url'))}
                  alt={title}
                  className={s.image}
                />
              </HomeGrid>
              <HomeGrid fluid direction="column">
                <HomeGrid className={s.briefDesc}>
                  <table className={s.table}>
                    <tbody>
                      {fields.map(item => {
                        const label = get(item, 'label', '');
                        const key = get(item, 'name');
                        let value;
                        if (key === BRANCH) {
                          value = get(detail, `${BRANCH}es`);
                        } else if (key === TYPE || key === METHOD) {
                          value = get(detail, `${key}s`);
                        } else {
                          value = get(detail, key);
                        }

                        if (value && Array.isArray(value)) {
                          value = value.map(b => get(b, 'name')).join(', ');
                        } else if (
                          value &&
                          typeof value !== 'string' &&
                          Object.keys(value).length > 0
                        ) {
                          value = get(value, 'name');
                        } else if (typeof value === 'boolean') {
                          value = value ? 'Có' : 'Không';
                        }
                        if (key === DEAL_DETAIL) {
                          value = 'Xem cuối trang';
                        }

                        return (
                          <tr key={label} className={s.tr}>
                            <td className={s.td}>{label}</td>
                            <td className={s.td}>{value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </HomeGrid>
              </HomeGrid>
            </HomeGrid>
            <HomeGrid
              component="div"
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={s.detailWrap}
            >
              <HomeGrid fluid className={s.detailText}>
                <textarea disabled value={detailText} className={s.richText} />
              </HomeGrid>
            </HomeGrid>
          </HomeGrid>
        </HomeGrid>
      </Fade>
      <Loading />
    </>
  );
};

Detail.propTypes = {
  deal: objectOf(any).isRequired,
  deletedDeal: objectOf(any),
  onClose: func.isRequired,
  dispatchResetDeal: func.isRequired,
  onUpdate: func.isRequired,
  onCopy: func.isRequired,
  onDelete: func.isRequired,
};

Detail.defaultProps = {
  deletedDeal: {},
};

export default connect(dealProps.mapStateToProps, dealProps.mapDispatchToProps)(Detail);
